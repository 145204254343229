<template>
  <div class="accountWrap">
    <!-- 表单 -->
    <div class="facilityBox">
      <el-form class="manageForm" :model="manageForm" :inline="true">
        <!-- <el-form-item prop="EnterpriseFullName">
          <el-input placeholder="请输入内容" v-model="manageForm.EnterpriseFullName" @keyup.enter.native="search()">
            <el-select v-model="manageForm.currentEnterprise" slot="prepend">
              <el-option label="货主及服务商单位" value="1"></el-option>
              <el-option label="货主单位" value="2"></el-option>
              <el-option label="服务商单位" value="3"></el-option>
            </el-select>
          </el-input>
        </el-form-item> -->
        <el-form-item label="货主单位：" prop="DemanderName">
          <el-input class="fromInp" v-model="manageForm.DemanderName" placeholder="输入货主单位" @keyup.enter.native="search()">
          </el-input>
        </el-form-item>
        <el-form-item label="服务商单位：" prop="SupplierName">
          <el-input class="fromInp" v-model="manageForm.SupplierName" placeholder="输入服务商单位"
            @keyup.enter.native="search()">
          </el-input>
        </el-form-item>
        <el-form-item label="开票申请编号：" prop="InvoiceTaskID">
          <el-input class="fromInp" v-model="manageForm.InvoiceTaskID" placeholder="输入开票申请编号"
            @keyup.enter.native="search()"></el-input>
        </el-form-item>
        <el-form-item label="发票类型：" prop="InvoiceType">
          <el-select v-model="manageForm.InvoiceType" clearable placeholder="请选择发票类型" @change="searchChange()">
            <el-option v-for="item in options" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="运力信息：" prop="DriverName">
          <el-input class="fromInp" v-model="manageForm.DriverName" placeholder="输入司机三要素或车牌号"
            @keyup.enter.native="search()"></el-input>
        </el-form-item>
        <div>
          <el-form-item label="开票日期：" prop="TaskDatetime">
            <el-date-picker v-model="manageForm.TaskDatetime" type="daterange" value-format="yyyy-MM-dd"
              start-placeholder="开始日期" end-placeholder="结束日期" @change="searchChange()"></el-date-picker>
          </el-form-item>
          <el-form-item label-width="10px">
            <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
            <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
            <el-button icon="el-icon-download" :disabled="!tableData.length" type="primary" size="medium"
              @click="exportExcel">导出表格
            </el-button>
          </el-form-item>
        </div>
      </el-form>
      <!--数量金额总计-->
      <!-- <div class="settleBox">
        <div style="display:flex;justify-content: space-between;">
          <div class="sum-item"><span class="sum-txt">发票数量(张)：</span><span class="sum-num">{{
            invoices.InvoiceCount ? invoices.InvoiceCount : '/'
          }}</span></div>
          <div class="sum-item">
            <span class="sum-txt">运单数量(条)：</span><span class="sum-num">{{
              invoices.WaybillCount ? invoices.WaybillCount : '/'
            }}</span>
          </div>
          <div class="sum-item"><span class="sum-txt">总计运费：</span><span class="sum-num">{{
            invoiceTotals.TotalCarriageAmount
          }}</span></div>
          <div class="sum-item"><span class="sum-txt">总计服务费/税费：</span><span class="sum-num">{{
           invoiceTotals.TotalTaxAmount
          }}</span></div>
          <div class="sum-item"><span class="sum-txt">总计运单总金额：</span><span class="sum-num">{{
            invoiceTotals.TotalPayTotalAmount
          }}</span></div>
        </div>
      </div> -->
      <el-table :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading">
        <el-table-column type="index" align="center" label="序号" width="50" fixed="left" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceTaskID" label="开票申请编号" width="200" fixed="left"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskType" label="业务类型" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ApplicantUserName" label="货主单位" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseName" label="服务商单位" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceCount" label="总计发票数量" width="110" show-overflow-tooltip>
        </el-table-column>
        <!-- <el-table-column align="center" prop="FeeTypeName" label="开票类型" width="110" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="Rate" label="费率" width="100" show-overflow-tooltip></el-table-column> -->
        <el-table-column align="center" prop="CarriageAmount" label="运费" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.CarriageAmount ? '￥' + scope.row.CarriageAmount : '/' }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TaxAmount" label="服务费/税费" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TaxAmount ? '￥' + scope.row.TaxAmount : '/' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayTotalAmount" label="运单总金额" width="110" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.PayTotalAmount ? '￥' + scope.row.PayTotalAmount : '/' }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" prop="InvoiceAmount" width="100" label="发票面额" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="InvoiceNumber" label="发票号" width="200" show-overflow-tooltip>
          </el-table-column> -->
        <el-table-column align="center" prop="CreateTime" label="开票日期" width="140" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="KPRemark" label="开票备注" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="goFacility(scope.row)" icon="el-icon-document">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin: 10px 0 0 0">
        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange"
          :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>

import { getDataDict } from "@/api/common/common.js";
import { DownloadPrintInvoiceTaskList, GetInvoiceTaskList } from "@/api/taxrelated/taxinfo/index";
import { taskSummary } from "@/api/finance/account/index";
export default {
  data() {
    return {
      // 筛选条件数据
      manageForm: {
        InvoiceNumber: "",
        InvoiceTaskID: "",
        DriverName: '',
        TaskDatetime: ['', ''],
        InvoiceType: "",
        FeeType: "",
        // EnterpriseFullName: '',
        // currentEnterprise: '1',
        DemanderName: '',
        SupplierName: '',
      },
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 表单数据
      tableData: [],
      // 服务商历史税务信息汇总
      invoices: "",
      // 发票类型数据
      options: [
        {
          Code: '',
          Name: '全部'
        }
      ],
      loading: true,
      invoiceTotals:{
        TotalCarriageAmount:'',
        TotalTaxAmount:'',
        TotalPayTotalAmount:''
      }
    };
  },
  methods: {
    searchChange() {
      this.search()
    },
    // 浏览器刷新重置参数
    beforeunloadHandler(e) {
      if (this.$route.path == '/taxrelated/taxinfo/index') {
        // 重置路由
        this.$router.push({
          path: "/taxrelated/taxinfo/index"
        });
      }
    },
    // 清空搜索
    resetForm() {
      this.loading = true;
      this.pagination.page = 1;
      this.manageForm = {
        InvoiceNumber: "",
        InvoiceTaskID: '',
        DriverName: '',
        TaskDatetime: ['', ''],
        InvoiceType: "",
        FeeType: '',
        // EnterpriseFullName: "",
        // currentEnterprise: '1',
        DemanderName: '',
        SupplierName: '',
      };
      this.ivoiceTasks();
      // this.taskSummary()
    },
    // 搜索
    search() {
      this.loading = true;
      this.pagination.page = 1;
      this.ivoiceTasks();
      // 汇总开票相关信息
      // this.taskSummary()
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true
      this.pagination.page = e;
      // 获取开票相关信息
      this.ivoiceTasks();
      // 汇总开票相关信息
      // this.taskSummary()
    },
    //查看结算单详情
    goFacility(item) {
      this.$router.push({
        path: "/taxrelated/taxinfo/tableinfo",
        query: {
          InvoiceNumber: this.manageForm.InvoiceNumber,
          InvoiceType: this.manageForm.InvoiceType,
          FeeType: this.manageForm.FeeType,
          InvoiceTaskID: this.manageForm.InvoiceTaskID,
          DriverName: this.manageForm.DriverName,
          taskId: item.InvoiceTaskID,
          pageIndex: this.pagination.page,
          StartDatetime: this.manageForm.TaskDatetime ? this.manageForm.TaskDatetime[0] : '',
          EndDatetime: this.manageForm.TaskDatetime ? this.manageForm.TaskDatetime[1] : '',
          // currentEnterprise: this.manageForm.currentEnterprise,
          // EnterpriseFullName: this.manageForm.EnterpriseFullName ? this.manageForm.EnterpriseFullName : '',
          DemanderName: this.manageForm.DemanderName,
          SupplierName: this.manageForm.SupplierName,
        },
      });
    },
    // 获取开票相关信息
    ivoiceTasks() {
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        InvoiceNumber: this.manageForm.InvoiceNumber,
        InvoiceTaskID: this.manageForm.InvoiceTaskID,
        DriverName: this.manageForm.DriverName,
        StartDatetime: this.manageForm.TaskDatetime ? this.manageForm.TaskDatetime[0] : '',
        EndDatetime: this.manageForm.TaskDatetime ? this.manageForm.TaskDatetime[1] : '',
        InvoiceType: this.manageForm.InvoiceType,
        FeeType: this.manageForm.FeeType,
        // EnterpriseFullName: this.manageForm.currentEnterprise == 1 ? this.manageForm.EnterpriseFullName : '',
        // DemanderName: this.manageForm.currentEnterprise == 2 ? this.manageForm.EnterpriseFullName : '',
        // SupplierName: this.manageForm.currentEnterprise == 3 ? this.manageForm.EnterpriseFullName : '',
        DemanderName: this.manageForm.DemanderName,
        SupplierName: this.manageForm.SupplierName,
        RoleType: 1
      };
      GetInvoiceTaskList({ Json: JSON.stringify(params) }).then((res) => {
        this.tableData = res.invoices;
        this.pagination.total = Number(res.totalRowCount);
        this.invoiceTotals.TotalCarriageAmount=res.totals[0].TotalCarriageAmount==0.00?'/':"￥"+res.totals[0].TotalCarriageAmount ;
          this.invoiceTotals.TotalTaxAmount=res.totals[0].TotalTaxAmount==0.00?'/':"￥"+ res.totals[0].TotalTaxAmount;
          this.invoiceTotals.TotalPayTotalAmount=res.totals[0].TotalPayTotalAmount==0.00?'/':"￥"+res.totals[0].TotalPayTotalAmount ;
        // this.taskSummary()
      }).finally(() => {
        this.loading = false;
      })
    },
    // 汇总开票相关信息
    taskSummary() {
      let data = {
        InvoiceNumber: this.manageForm.InvoiceNumber,
        InvoiceTaskID: this.manageForm.InvoiceTaskID,
        DriverName: this.manageForm.DriverName,
        StartDatetime: this.manageForm.TaskDatetime ? this.manageForm.TaskDatetime[0] : '',
        EndDatetime: this.manageForm.TaskDatetime ? this.manageForm.TaskDatetime[1] : '',
        InvoiceType: this.manageForm.InvoiceType,
        FeeType: this.manageForm.FeeType,
        // EnterpriseFullName: this.manageForm.currentEnterprise == 1 ? this.manageForm.EnterpriseFullName : '',
        // DemanderName: this.manageForm.currentEnterprise == 2 ? this.manageForm.EnterpriseFullName : '',
        // SupplierName: this.manageForm.currentEnterprise == 3 ? this.manageForm.EnterpriseFullName : '',
        DemanderName: this.manageForm.DemanderName,
        SupplierName: this.manageForm.SupplierName,
        RoleType: 1
      }
      taskSummary({ Json: JSON.stringify(data) }).then((res) => {
        this.invoices = res.data;
      });
    },
    //导出excel
    exportExcel() {
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        InvoiceNumber: this.manageForm.InvoiceNumber,
        InvoiceTaskID: this.manageForm.InvoiceTaskID,
        DriverName: this.manageForm.DriverName,
        StartDatetime: this.manageForm.TaskDatetime ? this.manageForm.TaskDatetime[0] : '',
        EndDatetime: this.manageForm.TaskDatetime ? this.manageForm.TaskDatetime[1] : '',
        InvoiceType: this.manageForm.InvoiceType,
        FeeType: this.manageForm.FeeType,
        // EnterpriseFullName: this.manageForm.currentEnterprise == 1 ? this.manageForm.EnterpriseFullName : '',
        // DemanderName: this.manageForm.currentEnterprise == 2 ? this.manageForm.EnterpriseFullName : '',
        // SupplierName: this.manageForm.currentEnterprise == 3 ? this.manageForm.EnterpriseFullName : '',
        DemanderName: this.manageForm.DemanderName,
        SupplierName: this.manageForm.SupplierName,
        RoleType: 1
      }
      DownloadPrintInvoiceTaskList({ Json: JSON.stringify(params) }).then(res => {
        window.location.href = res.data
      })
    }
  },
  created() {
    this.pagination.page = this.$route.query.pageIndex ? Number(this.$route.query.pageIndex) : 1
    this.manageForm.TaskDatetime[0] = this.$route.query.StartDatetime ? this.$route.query.StartDatetime : ''
    this.manageForm.TaskDatetime[1] = this.$route.query.EndDatetime ? this.$route.query.EndDatetime : ''
    // this.manageForm.currentEnterprise = this.$route.query.currentEnterprise ? this.$route.query.currentEnterprise : '1'
    // this.manageForm.EnterpriseFullName = this.$route.query.EnterpriseFullName ? this.$route.query.EnterpriseFullName : ''
    this.manageForm.DemanderName = this.$route.query.DemanderName ? this.$route.query.DemanderName : ''
    this.manageForm.SupplierName = this.$route.query.SupplierName ? this.$route.query.SupplierName : ''
    this.manageForm.InvoiceNumber = this.$route.query.InvoiceNumber ? this.$route.query.InvoiceNumber : ''
    this.manageForm.InvoiceTaskID = this.$route.query.InvoiceTaskID ? this.$route.query.InvoiceTaskID : ''
    this.manageForm.DriverName = this.$route.query.DriverName ? this.$route.query.DriverName : ''
    this.manageForm.InvoiceType = this.$route.query.InvoiceType ? this.$route.query.InvoiceType : ''
    this.manageForm.FeeType = this.$route.query.FeeType ? this.$route.query.FeeType : ''
    // 汇总开票相关信息
    // this.taskSummary()
    // 获取开票相关信息
    this.ivoiceTasks();
    // 获取发票类型
    getDataDict({ type: 20 }).then((res) => {
      this.options = this.options.concat(res.patterSetInfo)
    });
  },
  mounted() {
    // 监听浏览器刷新
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  destroyed() {
    // 移除浏览器刷新
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  }
};
</script>

<style >
.accountWrap .el-select .el-input {
  width: 130px;
}
</style>
<style lang="scss" scoped>
.accountWrap {
  .accountTitle {
    margin: 20px 0 0 0;
    font-size: 24px;
    padding: 0 32%;
    text-align: center;

    .accountInfo {
      width: 30vw;
      background: #f2f2f2;
      padding: 10px;
      border-radius: 10px;
      margin: 20px 0 0 0;
      font-size: 16px;
      line-height: 30px;
    }
  }
}

.settleBox {
  margin-bottom: 10px;
  padding: 10px 16px;
  background-color: #f0f0f0;
  color: #666;
}

.sum-item {
  color: rgb(255, 139, 23);
  margin-right: 15px;
  white-space: nowrap;

  .sum-txt {
    color: #333;
    font-weight: bold;
    text-align: right;
    width: 105px;
    display: inline-block;
  }

  .sum-num {
    display: inline-block;
    width: 200px;
  }
}
</style>